import "styles/pages/privacy-policy.scss";

import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import BreadcrumbsPolicy from "components/BreadcrumbsPolicy";

const PrivacyPolicy = ({ data }) => {

    const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};
  
    const seo = data.pagePrivacyPolicy?.nodes[0]?.seo;
    const privacy_policy_page_data = data.pagePrivacyPolicy?.nodes[0]?.pagePrivacyPolicy;
   
    return (
        <Layout seo={seo} header_setup={header_setup}>
            <section className="privacy-policy">
                <div className="privacy-policy__container">
                    <div className="section-title">{privacy_policy_page_data.privacyTitle}</div>
                    <div className="privacy-policy__content">
                        <BreadcrumbsPolicy parent="Strona główna" current="Polityka Prywatności" />
                        <div dangerouslySetInnerHTML={{ __html: privacy_policy_page_data.privacyContent }} />
                    </div>
                </div>
            </section>
        </Layout>
  );
};

export const query = graphql`
  {
    pagePrivacyPolicy: allWpPage(filter: {databaseId: {eq: 616}}) {
      nodes {
        seo {
          metaDesc
          title
        }
        pagePrivacyPolicy {
          privacyContent
          privacyTitle
        }
      }
    }
  }
`

export default PrivacyPolicy;